<template>
  <base-section
    id="features"
    class="secondary"
  >
    <v-responsive
      class="mx-auto"
      max-width="1350"
    >
      <v-container fluid>
        <v-row>
          <v-col
            v-for="(card,key) in cards"
            :key="key"
            cols="12"
            sm="4"
            md="4"
            :offset-md="key === 0 ? 2 : 0"
            :offset-sm="key === 0 ? 2 : 0"
          >
            <base-info-card
              align="center"
              dark
              v-bind="card"
            />
          </v-col>
        </v-row>
      </v-container>
    </v-responsive>
  </base-section>
</template>

<script>
  export default {
    name: 'SectionFeatures',

    data: () => ({
      cards: [
        {
          // icon: 'mdi-camera-outline',
          title: 'Qualité de travail',
          text: 'Des points d’honneur : une qualité d’exécution et des délais de réalisation courts et respectés.',
        },
        {
          // icon: 'mdi-keyboard-outline',
          title: 'Projet sur mesure',
          text: 'Nous sommes à l’écoute de vos demandes, même les plus originales et nous vous proposerons des solutions y répondant tout en se souciant de la fonctionnalité.',
        },
        // {
        //   title: 'Un large choix',
        //   text: 'Nous travaillons avec divers fournisseurs de confiance, cela nous permet de vous proposer divers matériaux, produits',
        // },
      ],
    }),
  }
</script>
